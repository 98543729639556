import './App.css';
import QrCodeGenerator from "./QrCodeGenerator";

function App() {

  return (
      <>
          <QrCodeGenerator/>
      </>
  );
}

export default App;
